import { mapGetters } from "vuex";
import moment from "moment";
export default {
  computed: {
    ...mapGetters("auth", ["getDefaultDateFormat"]),
  },
  methods: {
    generateColumns(component, getAllCompanyTemplatesData) {
      let tempData = {},
        result = [];
      result.push({
        key: "slotDate",
        label: "Date",
        type: "DATE",
      });
      (component.columns || []).forEach((col) => {
        let [t, k] = col.split("#");
        let template;
        if (tempData[t]) {
          template = tempData[t];
        } else {
          template = (getAllCompanyTemplatesData?.data || []).find(
            (te) => te._id == t
          );
          tempData[t] = template;
        }
        let field = (template?.sections?.[0]?.fields || []).find(
          (e) => e.key == k
        );
        if (field?.label) {
          result.push({
            key: col,
            label: field?.label,
            type: field.inputType,
            options: field.options,
            list_data: field.list_data,
          });
        }
      });
      return result;
    },
    generateMissingData(component, columns) {
      let result = [];
      (component?.missingIds || []).forEach((data) => {
        (data.missingBoundSlots || []).forEach((slot) => {
          let obj = {};
          (columns || []).forEach((col) => {
            if (col?.key == "slotDate") {
              return;
            }
            let c = col.key;
            let o = {};
            o["value"] = data?.dataslots?.[0]?.[c] || "";
            if (col.type == "LIST") {
              let option = col.list_data.find(
                (l) => l.value == data?.dataslots?.[0]?.[c]
              );
              if (option?.name) {
                o["value"] = option.name;
              }
            } else if (col.type == "DATE") {
              o["value"] = this.dateFormatter(o["value"]);
            }
            if (data?.dataslots?.[0]?.[c + "/name"]) {
              o["name"] = data.dataslots[0][c + "/name"];
            }
            obj[c] = o;
          });
          let v =
            this.dateFormatter(slot.start) +
            " - " +
            this.dateFormatter(slot.end);
          result.push({
            slotDate: {
              value: v,
            },
            _id: data._id,
            ...obj,
          });
        });
      });
      return result;
    },
    dateFormatter(value) {
      const format = this.getDefaultDateFormat
        ? this.getDefaultDateFormat
        : "MM-DD-YYYY";
      return moment(value, moment.ISO_8601, true).isValid()
        ? moment(value).format(format)
        : value;
    },
  },
};
